<template>
  <div class="static-page">
    <div class="content">
      <h1 class="page-header">Regulamin serwisu społecznościowego Takżyli.pl</h1>

      <article id="wstep" class="static-page-content-paragraph">
        <h3 class="static-page-content-paragraph-header">1. Wstęp</h3>

        <ol class="static-page-content-subparagraphs" start="1">
          <li>
            <p class="static-page-content-ordered-list-paragraph">
              Niniejszy regulamin określa zasady działania strony internetowej Takżyli.pl, zwanej dalej Serwisem oraz świadczenia i korzystania ze wszystkich oferowanych Usług Serwisu, takich między innymi jak przeglądanie, tworzenie oraz edycja profili.<br>
              Nazwa Serwisu, jego koncepcja, wygląd graficzny, logo, elementy grafiki, znaki towarowe, oprogramowanie oraz baza danych podlegają ochronie prawnej.
            </p>
          </li>
          <li>
            <p class="static-page-content-ordered-list-paragraph">
              Niniejszy Regulamin został sporządzony w oparciu o przepisy prawa obowiązujące na terytorium Rzeczypospolitej Polskiej i określa zasady działania Serwisu oraz świadczenia i korzystania z Usług. Regulamin określa prawa i obowiązki Użytkowników, a także prawa, obowiązki i zakres odpowiedzialności  Takżyli.pl  jako podmiotu zarządzającego i prowadzącego Serwis.
            </p>
          </li>
          <li>
            <p class="static-page-content-ordered-list-paragraph">
              Podmiotem prowadzącym Serwis Takżyli.pl jest TECHNOSPARES TRADING GROUP Sp Zoo Adres siedziby Salamandry 3/113, 03-982 Warszawa, Polska NIP: 7620002178
              REGON: 004587775
            </p>
          </li>
          <li>
            <p class="static-page-content-ordered-list-paragraph">
              Każdy Użytkownik zobowiązany jest do zapoznania się z Regulaminem i Polityką prywatności oraz przestrzegania ich postanowień, a także przepisów obowiązujących na terytorium Rzeczypospolitej Polskiej. Akceptacja regulaminu następuje w momencie zaznaczenia stosownego pola podczas rejestracji w serwisie. Akceptacja Regulaminu oznacza również akceptację szczegółowych zasad i warunków korzystania z poszczególnych funkcjonalności Serwisu.
            </p>
          </li>
        </ol>
      </article>

      <article id="definicje" class="static-page-content-paragraph">
        <h3 class="static-page-content-paragraph-header">II. Definicje</h3>

        <ol class="static-page-content-subparagraphs" start="1">
          <li>
            <p class="static-page-content-ordered-list-paragraph">
              Pojęcia wymienione w Regulaminie należy rozumieć następująco:<br>
              <strong>Administrator</strong> - Osoby wskazane przez podmiot zarządzający Serwisem.<br>
              <strong>Dane i Materiały</strong> - Wszelkie treści, w szczególności informacje, zdjęcia, filmy, wpisy, komentarze, które Użytkownik dobrowolnie umieszcza w Serwisie;<br>
              <strong>Dane Osobowe</strong> - Zgromadzone w ramach formularza profilu zmarłego / rejestracyjnego a następnie  modyfikowane dobrowolnie przez Użytkownika w trakcie korzystania z serwisu informacje dotyczące zidentyfikowanej lub możliwej do zidentyfikowania osoby fizycznej,są przetwarzane przez takżyli.pl w celu prawidłowego świadczenia Usług określonych w szczególności w Regulaminie Serwisu takżyli.pl, jak również w celach operacyjnych i statystycznych związanych z prowadzeniem Serwisu oraz w innych celach wskazanych w odpowiedniej zgodzie udzielonej przez Użytkownika. Pojęcie danych osobowych dotyczy również danych zbieranych automatycznie przez Administratora Serwisu, takich np. jak dane urządzenia, za pomocą którego użytkownik łączy się z Serwisem i dane lokalizacyjne wykorzystywane w celu zapewnienia bezpieczeństwa funkcjonowania Serwisu.
            </p>
          </li>
          <li>
            <p class="static-page-content-ordered-list-paragraph">
              <strong>takżyli.pl</strong> - Serwis społecznościowy dostępny pod adresem internetowym takżyli.pl.<br>
              <strong>Konto</strong> - Strona/przestrzeń serwerowa Serwisu, udostępniona Użytkownikowi, na której Użytkownik może przeglądać i zamieszczać Dane, z którymi mogą się zapoznać inni Użytkownicy, które również może wykorzystywać takżyli.pl na warunkach określonych w niniejszym Regulaminie oraz stosownie do zgód udzielonych przez danego Użytkownika;<br>
              <strong>Profil</strong> - Profil nazywany jest również Profilem Pamięci.Jest to profil osoby zmarłej oraz wykupione przez użytkownika miejsce o określonych limitach pamięci w którym może zamieszczać i modyfikować dane.<br>
              <strong>Materiały</strong> - Zdjęcia, filmy, teksty i inne treści umieszczane przez Użytkowników<br>
              <strong>Nazwa Profilu</strong> - ciąg znaków wygenerowanych przez system takżyli.pl umożliwiający późniejszą identyfikację profilu podczas korzystania z Serwisu.<br>
              <strong>Regulamin</strong> - Dokument określający zasady korzystania z Serwisu, w tym w szczególności prawa i obowiązki użytkowników oraz Serwisu wraz z załącznikami stanowiącymi jego integralną część;<br>
              <strong>Użytkownik</strong> - Osoba posiadająca pełną zdolność do czynności prawnych, która poprzez akceptację Regulaminu uzyskała dostęp do Usług. Użytkownikiem może być również osoba fizyczna małoletnia i/lub nieposiadająca pełnej zdolności do czynności prawnych, pod warunkiem uzyskania zgody przedstawiciela ustawowego;<br>
              <strong>Umowa</strong> - Umowa o świadczenie usług drogą elektroniczną zawarta między Użytkownikiem a takżyli.pl z chwilą akceptacji Regulaminu oraz Polityki prywatności przez Użytkownika, o treści odpowiadającej treści Regulaminu i Polityki prywatności.
            </p>
          </li>
        </ol>
      </article>

      <article id="rejestracja-i-zasady-korzystania" class="static-page-content-paragraph">
        <h3 class="static-page-content-paragraph-header">III. Rejestracja i zasady korzystania z Serwisu</h3>

        <ol class="static-page-content-subparagraphs" start="1">
          <li>
            <p class="static-page-content-ordered-list-paragraph">
              Użytkownikiem Serwisu może być każda osoba fizyczna mająca co najmniej 18 lat, która ukończy proces rejestracji oraz akceptuje treść Regulaminu w całości.            </p>
          </li>
          <li>
            <p class="static-page-content-ordered-list-paragraph">
              Rejestracja w Serwisie polega na poprawnym wypełnieniu formularza rejestracji oraz zaakceptowaniu Regulaminu i Polityki prywatności. Każdy użytkownik otrzymuje hasło do swojego konta na maila podanego w procesie rejestracji konta.
            </p>
          </li>
          <li>
            <p class="static-page-content-ordered-list-paragraph">
              Podczas procesu rejestracji Użytkownik zobowiązany jest do podania aktualnych i prawdziwych danych, a także do ich aktualizacji w przypadku wystąpienia zmian.
            </p>
          </li>
          <li>
            <p class="static-page-content-ordered-list-paragraph">
              Pozytywne ukończenie procesu rejestracji oznacza zawarcie Umowy na czas 25 lat na korzystanie z wybranych funkcjonalności Serwisu i korzystanie z wybranych przez Użytkownika płatnych usług i funkcjonalności.
            </p>
          </li>
          <li>
            <p class="static-page-content-ordered-list-paragraph">
              Użytkownik, który zawarł Umowę na korzystanie z Serwisu może otrzymywać na podany w trakcie rejestracji adres email oraz na swoje urządzenia końcowe powiadomienia o aktywności swojego konta lub o zmianach wprowadzonych w Serwisie
            </p>
          </li>
          <li>
            <p class="static-page-content-ordered-list-paragraph">
              Zarejestrowany Użytkownik Serwisu, ma prawo do:
              <ol type="a" class="static-page-content-inner-subparagraphs">
                <li>posiadania własnego Konta i Profilu w Serwisie,</li>
                <li>zmiany i usunięcia swoich danych poprzez wysłanie maila na <a href="mailto:biuro@takzyli.pl">biuro@takzyli.pl</a></li>
                <li>przeglądania Profili innych Użytkowników.</li>
              </ol>
            </p>
          </li>
          <li>
            <p class="static-page-content-ordered-list-paragraph">
              Niedozwolone jest:
              <ol type="a" class="static-page-content-inner-subparagraphs">
                <li>używanie wulgaryzmów i zwrotów powszechnie uważanych za obraźliwe, a także w jakikolwiek sposób naruszających godność osób opisywanych w Serwisie,</li>
                <li>umieszczanie materiałów pornograficznych,</li>
                <li>promowanie stron internetowych niezwiązanych z serwisem TakŻyli,</li>
                <li>umieszczanie Materiałów niezgodnych z regulaminem,</li>
                <li>używanie programów (botów) generujących zapytania do serwerów takżyli.pl. Wszelkie tego typu działania mogą zostać uznane jako atak DoS (Denial of Service). Wyjątek stanowią programy używające oficjalnego API Serwisu,</li>
                <li>rozsyłanie spamu,</li>
                <li>umieszczanie treści i materiałów o charakterze komercyjnym bez pisemnej zgody podmiotu prowadzącego Serwis,</li>
                <li>udostępniania danych osobowych osób trzecich bez ich zgody.</li>
              </ol>
            </p>
          </li>
          <li>
            <p class="static-page-content-ordered-list-paragraph">
              W przypadku wykrycia przez Administratora Serwisu treści, które naruszają postanowienia Regulaminu, Administrator Serwisu zastrzega sobie prawo do:
              <ol type="a" class="static-page-content-inner-subparagraphs">
                <li>usunięcia takiej treści;</li>
                <li>nałożenia Shadowbana na taką treść;</li>
                <li>nałożenia blokady na Konto Użytkownika.</li>
              </ol>
            </p>

            <p>Działania, o których mowa w ust. 1 powyżej, mogą być podejmowane w sposób zautomatyzowany poprzez systemy wykrywające treści naruszające postanowienia Regulaminu, niezależnie od tego, czy zostały one zgłoszone Administratorowi Serwisu, czy nie.</p>
          </li>
        </ol>
      </article>

      <article id="konto" class="static-page-content-paragraph">
        <h3 class="static-page-content-paragraph-header">IV. Konto</h3>

        <ol class="static-page-content-subparagraphs" start="1">
          <li>
            <p class="static-page-content-ordered-list-paragraph">
              Użytkownik może posiadać maksymalnie 1 Konto w Serwisie.
            </p>
          </li>
          <li>
            <p class="static-page-content-ordered-list-paragraph">
              Przekazywanie hasła do Konta osobom trzecim jest zabronione.
            </p>
          </li>
          <li>
            <p class="static-page-content-ordered-list-paragraph">
              Z Konta może korzystać wyłącznie Użytkownik. Zabronione jest zakładanie i używanie kont przez 2 lub więcej osób jednocześnie. Wyjątek stanowią Profile partnerów b2b.
            </p>
          </li>
          <li>
            <p class="static-page-content-ordered-list-paragraph">
              Użytkownik ma prawo do usunięcia Konta samodzielnie bądź kontaktując się z Administratorem Serwisu. Usunięcie konta powoduje rozwiązanie Umowy z Serwisem.<br>
              Usunięcie konta skutkuje skasowaniem z Serwisu wszelkich danych osobowych Użytkownika, za wyjątkiem danych osobowych, które Serwis jest zobligowany przechowywać na mocy innych przepisów oraz danych statystycznych poddanych anonimizacji. Procedura całkowitego usunięcia konta wraz z danymi osobowymi może trwać do 30 dni .
            </p>
          </li>
          <li>
            <p class="static-page-content-ordered-list-paragraph">
              W przypadkach łamania postanowień niniejszego Regulaminu Konto Użytkownika może zostać zablokowane tymczasowo, trwale (Ban) lub usunięte. Blokada lub usunięcie może być jednocześnie zastosowane do wszystkich Kont Użytkownika. Jednocześnie, Administrator Serwisu zastrzega sobie prawo do zastosowania Shadowbana w stosunku do określonych treści zamieszczanych w Serwisie przez Użytkownika, które naruszają postanowienia Regulaminu, jak również innych środków wskazanych w art. 14 Regulaminu.
            </p>
          </li>
          <li>
            <p class="static-page-content-ordered-list-paragraph">
              Śmierć użytkownika powoduje rozwiązanie Umowy z Serwisem.
            </p>
          </li>
        </ol>
      </article>

      <article id="materialy-uzytkownika" class="static-page-content-paragraph">
        <h3 class="static-page-content-paragraph-header">V. Materiały Użytkownika</h3>

        <ol class="static-page-content-subparagraphs" start="1">
          <li>
            <p class="static-page-content-ordered-list-paragraph">
              Użytkownik ponosi pełną odpowiedzialność za Materiały umieszczane w Serwisie, w szczególności zdjęcia, filmy, teksty.
            </p>
          </li>
          <li>
            <p class="static-page-content-ordered-list-paragraph">
              Serwis w żaden sposób nie ponosi odpowiedzialności za nieautoryzowane wykorzystanie przez osoby trzecie umieszczonych w Serwisie Materiałów. Wszelką odpowiedzialność ponosi Użytkownik.
            </p>
          </li>
          <li>
            <p class="static-page-content-ordered-list-paragraph">
              Administrator nie ponosi odpowiedzialności w granicach zgodnych z obowiązującymi przepisami prawa i zasadami współżycia społecznego, za Materiały przekazywane i publikowane na łamach Serwisu przez Użytkowników, jednakże dołoży wszelkich starań, aby usuwać/blokować materiały niezgodne z Regulaminem.
            </p>
          </li>
          <li>
            <p class="static-page-content-ordered-list-paragraph">
              Użytkownik akceptując Regulamin oświadcza, że posiada pełnię praw do zamieszczanych przez siebie Materiałów.
            </p>
          </li>
          <li>
            <p class="static-page-content-ordered-list-paragraph">
              Administrator Serwisu ma prawo do zmniejszania/kompresowania Materiałów Użytkownika. Nie ma też obowiązku informowania Użytkownika o tym fakcie.
            </p>
          </li>
          <li>
            <p class="static-page-content-ordered-list-paragraph">
              Administrator Serwisu ma prawo do wykorzystywania Materiałów użytkownika w treściach promocyjnych, w szczególności wykorzystywania ich w powiadomieniach email wysyłanych do innych Użytkowników, publikowania ich na stronie (funpage) w Serwisach takich jak Facebook, Instagram oraz YouTube.
            </p>
          </li>
          <li>
            <p class="static-page-content-ordered-list-paragraph">
              Niezastosowanie się do poleceń Administratora w trybie art. 32 skutkuje nałożeniem blokady stałej bądź czasowej lub całkowitym usunięciem konta.
            </p>
          </li>
        </ol>
      </article>

      <article id="uslugi-i-funkcje" class="static-page-content-paragraph">
        <h3 class="static-page-content-paragraph-header">VI. Usługi i funkcje</h3>

        <ol class="static-page-content-subparagraphs" start="1">
          <li>
            <p class="static-page-content-ordered-list-paragraph">
              Usługa oferowana przez portal takzyli.pl polega na mozliwosci utworzenia profilu osoby zmarłej. Serwis pobiera jednorazową opłatę aktywacyjną profil zmarłego. Serwis zobowiązuje się udostępniać profil w sieci na okres 25 lat od daty założenia.            </p>
          </li>
          <li>
            <p class="static-page-content-ordered-list-paragraph">
              Warunki założenia profilu<br>
              <ol class="static-page-content-inner-subparagraphs" start="1">
                <li>Zgodnie z ideą strony, profil osoby zmarłej może zostać utworzony wyłącznie przez osobę, która posiada zgodę spadkobierców zmarłego na jego utworzenie. Zgoda ta powinna zawierać wyraźną informację o celu i zakresie wykorzystania danych osobowych i wizerunku zmarłego w ramach profilu.</li>
                <li>Twórca profilu musi również posiadać zgody na przetwarzanie wizerunku wszystkich osób, które są widoczne na dodawanych zdjęciach. Zdjęcia nie mogą zawierać osób nieletnich bez zgody ich opiekunów prawnych. Wymagana zgoda dotyczy również wszystkich danych podawanych na profilu.</li>
                <li>Twórca profilu zobowiązany jest do zachowania pełnej ochrony dóbr osobistych osoby zmarłej. Oznacza to, że profil nie może zawierać treści obraźliwych, poniżających lub naruszających prywatność zmarłego. Prezentacja zmarłego w sposób negatywny jest kategorycznie zabroniona.</li>
              </ol>
            </p>
          </li>
        </ol>
      </article>

      <article id="reklamacje" class="static-page-content-paragraph">
        <h3 class="static-page-content-paragraph-header">VII. Reklamacje</h3>

        <ol class="static-page-content-subparagraphs" start="1">
          <li>
            <p class="static-page-content-ordered-list-paragraph">
              Wszelkie reklamacje powinny być zgłaszane za pomocą formularza kontaktowego bądź maila <a href="mailto:biuro@takzyli.pl">biuro@takzyli.pl</a>
            </p>
          </li>
        </ol>
      </article>

      <article id="postanowienia-koncowe" class="static-page-content-paragraph">
        <h3 class="static-page-content-paragraph-header">VIII. Postanowienia końcowe</h3>

        <ol class="static-page-content-subparagraphs" start="1">
          <li>
            <p class="static-page-content-ordered-list-paragraph">
              Regulamin wraz z jego załącznikami dostępny jest w aktualnej wersji pod adresem <a href="https://takzyli.pl/regulamin">takzyli.pl/regulamin</a>
            </p>
          </li>
          <li>
            <p class="static-page-content-ordered-list-paragraph">
              Podmiot prowadzący Serwis zastrzega sobie prawo do wprowadzania zmian w Regulaminie z ważnych powodów, tj.:
              <ol class="static-page-content-inner-subparagraphs">
                <li>spowodowanych potrzebą zmian w aspekcie technologicznym, ekonomicznym lub organizacyjnym prowadzenia działalności Serwisu;</li>
                <li>spowodowanych potrzebą zmian w strukturze lub w treści Serwisu lub w ofercie Serwisu;</li>
                <li>z powodu zmiany zakresu usług świadczonych przez podmiot prowadzący Serwis, poprzez modyfikację, wprowadzenie nowych lub wycofanie przez podmiot prowadzący Serwis dotychczasowych funkcjonalności lub usług objętych Regulaminem;</li>
                <li>z powodu zapewnienia bezpieczeństwa informacji, jakie są pozyskiwane lub przetwarzane w związku ze świadczeniem usług objętych Regulaminem;</li>
                <li>z powodu zmian powszechnie obowiązujących przepisów prawa, które mają wpływ na treść Regulaminu, w tym prawa i obowiązki wynikające z Regulaminu lub zmiany interpretacji powyższych przepisów prawa wskutek orzeczeń sądów, decyzji, nakazów, zaleceń, wytycznych właściwych w danym zakresie urzędów lub organów;</li>
                <li>z powodu konieczności dostosowania Regulaminu do zaleceń, nakazów, orzeczeń, wytycznych lub decyzji właściwych w danym zakresie urzędów lub organów;</li>
                <li>z powodu konieczności dokonania korekty oczywistych omyłek lub błędów pisarskich albo uzupełnienia luk lub nieścisłości w Regulaminie niedających się usunąć w wyniku wykładni zawartych postanowień;</li>
                <li>z powodu zmiany podwykonawcy lub podwykonawców.</li>
              </ol>
            </p>
          </li>
          <li>
            <p class="static-page-content-ordered-list-paragraph">
              Podmiot prowadzący Serwis informuje Użytkowników o proponowanych zmianach Regulaminu nie później niż 30 dni przed proponowaną datą ich wejścia w życie. Jeżeli przed proponowaną datą wejścia w życie zmian Regulaminu Użytkownik nie zgłosi podmiotowi prowadzącemu Serwis sprzeciwu wobec tych zmian, uznaje się, że Użytkownik wyraził na nie zgodę. Użytkownik ma prawo, przed dniem proponowanego wejścia w życie zmian Regulaminu, wypowiedzieć Umowę ze skutkiem na dzień wejścia w życie zmian Regulaminu.
            </p>
          </li>
        </ol>
      </article>
    </div>
  </div>
</template>

<script>
export default {
  name: 'TermsOfService',
};
</script>

<style lang="scss" scoped>
.static-page {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 16px 16px 0;

  @media (min-width: 768px) {
    padding: 24px 24px 0;
    height: 100%;
  }

  .content {
    max-width: 960px;
  }

  .page-header {
    font-size: 1rem;
    text-align: center;
    margin-bottom: 16px;

    @media (min-width: 768px) {
      font-size: 2rem;
      text-align: left;
      margin-bottom: 32px;
    }
  }

  .static-page-content-paragraph-header {
    font-family: Roboto, sans-serif;
    margin-bottom: 24px;

    @media (min-width: 768px) {
      margin-bottom: 32px;
    }
  }

  .static-page-content-subparagraphs,
  .static-page-content-ordered-list-paragraph,
  .static-page-content-paragraph {
    line-height: 150%;
    letter-spacing: 0.5px;
    font-size: 0.875rem;
    margin: 16px 0;
    text-align: justify;
    font-family: Roboto, sans-serif;

    @media (min-width: 768px) {
      font-size: 1.25rem;
      letter-spacing: 0.1px;
    }
  }

  .static-page-content-paragraph {
    @media (min-width: 768px) {
      padding: 0 24px;
    }
  }

  .static-page-content-unordered-list,
  .static-page-content-subparagraphs {
    margin-left: 16px;
  }

  .static-page-content-ordered-list-paragraph,
  .static-page-content-subparagraphs {
    @media (min-width: 768px) {
      margin-left: 24px;
    }
  }

  .static-page-content-inner-subparagraphs {
    margin-left: 3.2rem;
  }
}
</style>